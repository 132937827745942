import React from "react"
import { Helmet } from "react-helmet"
import Swiper from "react-id-swiper"
import cn from "classnames"
import ActionLink from "../components/ActionLink/index"
import ActionButton from "../components/ActionButton/index"
import SIMPSON_LOGO from "../images/ssy-mono.svg"
import SINGAPORE_LOGO from "../images/singapore-logo.svg"
import AAL_LOGO from "../images/aal_logo.svg"
import WESTERN_BULK from "../images/western-bulk_logo.svg"
import SLIDER_QUOTE_IMG from "../images/slider-quote.svg"
import STAT_1_IMG from "../images/stat1.svg"
import STAT_2_IMG from "../images/stat2.svg"
import STAT_3_IMG from "../images/stat3.svg"
import Data from "../data/data"
import style from "./home.module.css"

const HomePage = () => {

  // Settings for client quote carousel
  const params = {
    slidesPerView: "auto",
    spaceBetween: 30,
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Data.metaData.siteTitle}</title>
        <meta name="description" content={Data.metaData.description.homepage} />
        <link
          href="https://fonts.googleapis.com/css?family=Raleway:700&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <div className={style.root}>
        <div className={style.banner}>
          <div className="container">
            <div className="row">
              <div className="col s12">
                <div className={style.banner__content}>
                  <h1 className={style.banner__heading}>
                    {Data.homePage.heading}
                  </h1>
                  <div className="row">
                    <div className="col s12">
                      <p className={style.banner__subHeading}>
                        {Data.homePage.subHeading}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col s12">
                      <ActionButton
                        label={Data.homePage.requestDemoButton}
                        url={"/contact"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={style.logos}>
          <div className="container">
            <div className={`row ${style.customRow}`}>
              <div className="col s12">
                <div className={style.logos__heading}>
                  <h4 className={style.logos__headingText}>
                    {Data.homePage.logoHeading}
                  </h4>
                </div>
                <div className={style.homeLogos}>
                  <img
                    className={cn(style.homeLogoImg, style.simpsopn)}
                    src={SIMPSON_LOGO}
                    alt="Simpson Logo"
                  />
                  <img
                    className={cn(style.homeLogoImg, style.swiss)}
                    src={SINGAPORE_LOGO}
                    alt="Swiss Marine Logo"
                  />
                  {/* <img
                    className={cn(style.homeLogoImg, style.torm)}
                    src={TORM_LOGO}
                    alt="Torm Logo"
                  /> */}
                  <img
                    className={cn(style.homeLogoImg, style.aal)}
                    src={AAL_LOGO}
                    alt="AAL Logo"
                  />
                  <img
                    className={cn(style.homeLogoImg, style.western)}
                    src={WESTERN_BULK}
                    alt="Western Bulk Logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={style.homeServicesSection}>
          <div className="container">
            <div className={`row ${style.customRow}`}>
              <div className={`col s12 ${style.customColumn}`}>
                {/* Freight Forwarders */}
                <div className={style.serviceWrapper}>
                  <div className={style.serviceLink}>
                    <p className={style.serviceLinkText}>
                      Freight
                      <br />
                      Forwarders
                    </p>
                    <div className={style.serviceBtn}>
                      <ActionLink
                        label={"Find out more"}
                        linkPath={"/freight-forwarders"}
                      />
                    </div>
                  </div>
                  <div className={cn(style.serviceInfo, style.freightInfo)}>
                    <p className={style.serviceInfoText}>
                      Turn documents into structured data
                    </p>
                  </div>
                </div>

                {/* Chartering Teams */}
                <div className={style.serviceWrapper}>
                  <div className={style.serviceLink}>
                    <p className={style.serviceLinkText}>
                      Chartering
                      <br />
                      Teams
                    </p>
                    <div className={style.serviceBtn}>
                      <ActionLink
                        label={"Find out more"}
                        linkPath={"/chartering-teams"}
                      />
                    </div>
                  </div>
                  <div className={cn(style.serviceInfo, style.charteringInfo)}>
                    <p className={style.serviceInfoText}>
                      Clutter-free chartering workflow that connects to your
                      existing applications
                    </p>
                  </div>
                </div>

                {/* Ship Brokers */}
                <div className={style.serviceWrapper}>
                  <div className={style.serviceLink}>
                    <p className={style.serviceLinkText}>
                      Ship
                      <br />
                      brokers
                    </p>
                    <div className={style.serviceBtn}>
                      <ActionLink
                        label={"Find out more"}
                        linkPath={"/ship-brokers"}
                      />
                    </div>
                  </div>
                  <div className={cn(style.serviceInfo, style.shipInfo)}>
                    <p className={style.serviceInfoText}>
                      Simple-to-use automatic position lists
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Client Quotes */}
          <div className={style.homepageQuotesSection}>
            <Swiper {...params}>
              <div className={style.quoteSlide}>
                <img
                  className={style.quote}
                  src={SLIDER_QUOTE_IMG}
                  alt="Quote"
                />
              </div>
            </Swiper>
          </div>
        </div>

        <div className={style.homeStatsSection}>
          <div className="container">
            <div className={`row ${style.customRow}`}>
              <div className={`col s12 ${style.customColumn}`}>
                <div className={style.homeStatsWrapper}>
                  {/* 15 Million Emails */}
                  <div className={style.statsBlock}>
                    <div className={style.statsBlockItem}>
                      <img
                        className={cn(style.statsBlockImg)}
                        src={STAT_1_IMG}
                        alt="Reduction Icon"
                      />
                    </div>
                  </div>

                  {/* Organisations */}
                  <div className={style.statsBlock}>
                    <div
                      className={cn(style.statsBlockItem, style.organisation)}
                    >
                      <img
                        className={cn(style.statsBlockImg)}
                        src={STAT_2_IMG}
                        alt="Organisations Icon"
                      />
                    </div>
                  </div>

                  {/* Reduction */}
                  <div className={style.statsBlock}>
                    <div className={style.statsBlockItem}>
                      <img
                        className={cn(style.statsBlockImgn)}
                        src={STAT_3_IMG}
                        alt="Reduction Icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

HomePage.propTypes = {}

export default HomePage
