import React from "react"
import PropTypes from "prop-types"
import style from "./action-button.module.css"

const ActionButton = ({ label, url }) => {
  return (
    <div className={style.root}>
      <a href={url} className={style.buttonLink}>
        {label}
      </a>
    </div>
  )
}

ActionButton.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default ActionButton
