import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import style from "./action-link.module.css"

const ActionLink = ({ label, linkPath }) => {
  return (
    <div className={style.root}>
      <Link className={style.actionLink} to={linkPath}>
        {label}
      </Link>
    </div>
  )
}

ActionLink.propTypes = {
  label: PropTypes.string.isRequired,
  linkPath: PropTypes.string.isRequired,
}

export default ActionLink
